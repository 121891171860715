import React from "react";
import { Container, Typography, Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Box mt={4} mb={4}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We at MySwiss Theofanakis (“Company”, “we”, “us”, or “our”) are
          committed to protecting your privacy. This Privacy Policy explains how
          we collect, use, and protect your data when using www.myswisss.ch.
        </Typography>

        {/* Added clarification on personal data based on GDPR */}
        <Typography variant="h5" gutterBottom>
          1. What This Policy Covers
        </Typography>
        <Typography variant="body1" paragraph>
          This policy explains how we process personal data collected via our
          website. We comply with the Swiss Federal Act on Data Protection
          (FADP), the New Federal Act on Data Protection (nFADP), and the EU
          General Data Protection Regulation (GDPR).nd the European Union’s
          General Data Protection Regulation (GDPR).
        </Typography>
        <Typography variant="h5" gutterBottom>
          2. Data Controller
        </Typography>
        <Typography variant="body1" paragraph>
          The data controller is MySwiss Theofanakis, based in Switzerland. You
          can contact us via: Email: team.myswisss@gmail.com
        </Typography>

        {/* Add more details on the data collection */}
        <Typography variant="h5" gutterBottom>
          3. Data We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect the following categories of personal data:
          <ul>
            <li>
              <b>User Data:</b> Name, email, and browsing behavior for website
              users.
            </li>
            <li>
              <b>Business Data:</b> Photos, menus, and other business-related
              details uploaded by clients.
            </li>
            <li>
              <b>Technical Data:</b> IP addresses, device types, and analytics
              data.
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          4. How We Use Your Data?
        </Typography>
        <Typography variant="body1" paragraph>
          <ul>
            <li>Showcase businesses to website users. </li>
            <li>
              To send transactional emails related to bookings or inquiries.{" "}
            </li>{" "}
            <li>
              To improve our platform and user experience through analytics.{" "}
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          Access: You may request access to the personal data we hold about you.
          Correction: You may correct or update your personal data. <br></br>
          Deletion: You may request the deletion of your data at any time.
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. Cookies and Analytics
        </Typography>
        <Typography variant="body1" paragraph>
          Our website uses cookies to provide you with a better browsing
          experience. Cookies collect information about your browsing habits and
          preferences. You may disable cookies in your browser settings;
          however, certain features of our website may not function properly.
        </Typography>

        <Typography variant="h5" gutterBottom>
          7. Security of Data
        </Typography>
        <Typography variant="body1" paragraph>
          We take all reasonable measures to protect your data. However, no
          method of online data transfer is entirely secure.
        </Typography>

        {/* Final contact information */}
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          MySwiss Theofanakis <br />
          Dietlikerstrasse 69, 8302 Kloten, Switzerland <br />
          Email: team.myswisss@gmail.com
          <br />
          Phone: +41 76 222 42 83
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
