// ScrollToTop.js
import React, { useState, useEffect } from "react";
import { Fab, Zoom } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/system";

const ScrollButton = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(4),
  right: theme.spacing(4),
  backgroundColor: "transparent",
  color: "#black",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  zIndex: 1000,
}));

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(true); // Force visibility for debugging

  const checkScrollTop = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setShowScroll(scrollY > 100); // Show button after 100px scroll
  };

  const scrollToTop = () => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.body.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);

  // Temporarily remove Zoom for debugging
  return (
    // <Zoom in={showScroll}>
    <ScrollButton onClick={scrollToTop} aria-label="scroll to top">
      <KeyboardArrowUpIcon />
    </ScrollButton>
    // </Zoom>
  );
};

export default ScrollToTop;
