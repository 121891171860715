import React from "react";
import { Container, Typography, Box } from "@mui/material";

const TermsOfService = () => {
  return (
    <Container>
      <Box mt={4} mb={4}>
        <Typography variant="h4" gutterBottom>
          General Terms and Conditions of Business (GTC)
        </Typography>
        <Typography variant="body1" paragraph>
          These General Terms and Conditions of Business (hereinafter referred
          to as “GTC”) govern the relationship between MySwiss Theofanakis
          (“Company”, “we”, “us”) and business clients (“Clients”). By using our
          services, you accept these terms.
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Scope
        </Typography>
        <Typography variant="body1" paragraph>
          These terms apply to all bookings, contracts, and services provided by
          MySwiss Theofanakis.Amendments to these terms must be in writing and
          agreed upon by both parties.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Payment and Prices
        </Typography>
        <Typography variant="body1" paragraph>
          The payment terms and conditions are handled directly between MySwiss
          Theofanakis and business clients through a separate agreement. For
          inquiries, please contact us at team.myswisss@gmail.com.
        </Typography>

        <Typography variant="h5" gutterBottom>
          3. Cancellations
        </Typography>
        <Typography variant="body1" paragraph>
          Clients can cancel at any time during the free trial without incurring
          any charges. Cancellations after the trial period are effective at the
          end of the paid term. No refunds will be provided for the remainder of
          the term. Cancellations must be submitted in writing to
          team.myswisss@gmail.com.
        </Typography>

        <Typography variant="h5" gutterBottom>
          4. Liability
        </Typography>
        <Typography variant="body1" paragraph>
          MySwiss Theofanakis provides a platform for business promotion but is
          not responsible for errors in listings, incorrect information provided
          by clients, or technical issues. The Company is not liable for any
          damages, losses, or missed opportunities arising from the use of the
          platform.
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Manager Dashboard Usage
        </Typography>
        <Typography variant="body1" paragraph>
          Business clients are responsible for the content uploaded via their
          dashboard, including photos, descriptions, and menus. Content must
          comply with Swiss and EU laws.
        </Typography>

        {/* Add more details as needed */}
        <Typography variant="h5" gutterBottom>
          5. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about these terms, please contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          MySwiss Theofanakis <br />
          Dietlikerstrasse 69, 8302 Kloten, Switzerland
          <br />
          Email: team.myswisss@gmail.com
          <br />
          Phone: +41 76 222 42 83
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
