import React from "react";
import { Container, Typography, Box } from "@mui/material";

const LegalDisclaimer = () => {
  return (
    <Container>
      <Box mt={4} mb={4}>
        <Typography variant="h4" gutterBottom>
          Legal Disclaimer
        </Typography>
        <Typography variant="body1" paragraph>
          MySwiss Theofanakis (“Company”) operates the website located at
          www.myswisss.ch. This website serves as a platform for visitors and
          business owners to explore and showcase businesses in Zurich and other
          cities in Switzerland. By using this website, you agree to the
          following terms:
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Ownership
        </Typography>
        <Typography variant="body1" paragraph>
          All content, trademarks, and logos on this website are the property of
          MySwiss Theofanakis unless otherwise noted. Any reproduction,
          distribution, or use of the content without prior written permission
          is prohibited.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Content Accuracy
        </Typography>
        <Typography variant="body1" paragraph>
          While we strive to provide accurate and up-to-date information,
          MySwiss Theofanakis does not guarantee the completeness or accuracy of
          the content. We are not responsible for any errors or omissions.
        </Typography>

        <Typography variant="h5" gutterBottom>
          3. External Links
        </Typography>
        <Typography variant="body1" paragraph>
          This website may include links to external websites not operated by
          MySwiss Theofanakis. We have no control over the content or practices
          of these sites and disclaim any responsibility for them.
        </Typography>

        <Typography variant="h5" gutterBottom>
          4. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          MySwiss Theofanakis is not liable for any direct, indirect, or
          incidental damages arising from the use or inability to use the
          website or its content.
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          For any legal inquiries, you can contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          MySwiss Theofanakis <br />
          Dietlikerstrasse 69, 8302 Kloten, Switzerland <br />
          Email: team.myswisss@gmail.com
          <br />
          Phone: +41 76 222 42 83
        </Typography>
      </Box>
    </Container>
  );
};

export default LegalDisclaimer;
